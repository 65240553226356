import React, { useContext, useState } from 'react';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import '../../assets/Header.scss'
import { Link, useNavigate } from 'react-router-dom';
import { CommonContext } from '../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const { handleLogout, isHovered, isSidebarOpen } = useContext(CommonContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Navbar color="light" light expand="md" className={`header-main ${isSidebarOpen || isHovered ? '' : 'collapsed'}`}>
      <Nav className="mr-auto" navbar>
        <NavItem>
          {/* <Input type="search" placeholder="Search" /> */}
        </NavItem>
      </Nav>
      <Nav navbar>
        <NavItem>
          <Link to="/downloads" className="nav-link mr-2">
          <FontAwesomeIcon icon={faDownload} />
          </Link>
        </NavItem>
        <Dropdown nav isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle nav caret>
            Admin
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem href="/profile">Profile</DropdownItem>
            <DropdownItem href="/site-settings">Settings</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Nav>
    </Navbar>
  );
};

export default Header;
