import React, { createContext, useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { useLocation, useNavigate } from 'react-router-dom';
import commonApi from '../utiles/api';
import { Badge } from 'reactstrap';

export const CommonContext = createContext();

export function CommonContextProvider({ children }) {
    const navigate = useNavigate();
    const location = useLocation();
    const decryptData = (encryptedData, secretKey) => {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    };
    const handleLogout = () => {
        localStorage.removeItem('userIOV');
        localStorage.removeItem('iovToken');
        localStorage.removeItem('iovConfig');
        navigate('/login')
    };
    const secretKey = 'app-iov';
    const storedUserData = localStorage.getItem('userIOV');
    const configData = localStorage.getItem('iovConfig');

    const [addModal, setAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [globalSearch, setGlobalSearch] = useState('');
    const [cityDropDown, setCityDropDown] = useState('');
    const [stateDropDown, setStateDropDown] = useState('');
    const [financialYearDropDown, setFinancialYearDropDown] = useState('');
    const [feeStructureDrop, setFeeStructureDrop] = useState('');
    const [configVar, setConfigVar] = useState('');
    const [nbCategory, setNbCategory] = useState([]);
    const [user, setUser] = useState(null);
    const [userPermission, setUserPermission] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
    const [isHovered, setIsHovered] = React.useState(false);
    const [settingOptions, setSettingOptions] = useState({})
    const [permission, setPermission] = useState({});
    const [formTemp, setFormTemp] = useState({
        title: '',
        link: '',
        status: '1',
        sort_order: '',
        file: null,
    });
    const handleAddModals = () => {
        setAddModal(true);
    };
    const handleCloseModal = () => {
        setAddModal(false);
    };
    const onCityDropDown = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('city/dropdown');
            if (response.data.s === 1) {
                setCityDropDown(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onStateDropDown = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('state/dropdown');
            if (response.data.s === 1) {
                setStateDropDown(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const allPermissions = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('users/permissions');
            if (response.data.s === 1) {
                setPermission(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onsetFYDropDown = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('financial-year/dropdown');
            if (response.data.s === 1) {
                setFinancialYearDropDown(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onsetFSDropDown = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('fee-structures/dropdown');
            if (response.data.s === 1) {
                setFeeStructureDrop(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onNbCategory = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('category/dropdown');
            if (response.data.s === 1) {
                setNbCategory(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onSiteSetting = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('site-settings/get');
            if (response.data.s === 1) {
                setSettingOptions(response.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {if (
            location?.pathname && // Ensure pathname exists
            !location.pathname.startsWith('/member-profile') &&
            !location.pathname.startsWith('/individual-member')
        ) {
            localStorage.removeItem('currentPage');
            localStorage.removeItem('prPage');
        }
    }, [location]);

    console.log('location', location);

    useEffect(() => {
        if (configData) {
            setConfigVar(JSON.parse(configData));
        }
    }, [configData]);
    useEffect(() => {
        if (storedUserData) {
            const parsedUser = decryptData(storedUserData, secretKey);
            if (parsedUser?.permissions) {
                setUserPermission(JSON.parse(parsedUser?.permissions))
            }
            setUser(parsedUser);
            onCityDropDown();
            onStateDropDown();
            onSiteSetting();
            onNbCategory();
            onsetFYDropDown();
            onsetFSDropDown();
            allPermissions();
        }
        if (!storedUserData) {
            navigate('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedUserData]);
    const renderFilePreview = (fileUrl, width = '100px') => {
        const fileExtension = fileUrl.split('.').pop().toLowerCase();
        const isPdf = fileExtension === 'pdf';

        if (isPdf) {
            return (
                <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                    <embed
                        src={fileUrl}
                        type="application/pdf"
                        width={width}
                        height={width}
                    />
                </a>
            );
        } else {
            return (
                <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                    <img
                        src={fileUrl}
                        alt="Selected File"
                        style={{ maxWidth: '100%', width }}
                    />
                </a>
            );
        }
    };
    const renderFileDownloadButton = (fileUrl) => {
        return (
            <a href={fileUrl} download target="_blank" rel="noopener noreferrer">
                <Badge type="button" color="info">
                    Download File
                </Badge>
            </a>
        );
    };

    const handlePasteShared = (event) => {
        event.preventDefault();
        console.log('event', event)
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedContent = clipboardData.getData('text/html') || clipboardData.getData('text/plain');


        event.target.editor.selection.insertHTML(pastedContent || '');
    };

    function numberToWords(num) {
        const a = [
            'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
            'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen',
            'Eighteen', 'Nineteen'
        ];
        const b = [
            '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
        ];
        const c = [
            '', 'Thousand', 'Million', 'Billion', 'Trillion'
        ];

        if (num === 0) return a[0];
        let words = '';
        let i = 0;

        while (num > 0) {
            if (num % 1000 !== 0) {
                words = numberToWordsLessThan1000(num % 1000) + c[i] + ' ' + words;
            }
            num = Math.floor(num / 1000);
            i++;
        }

        return words.trim();

        function numberToWordsLessThan1000(num) {
            if (num === 0) return '';
            else if (num < 20) return a[num] + ' ';
            else if (num < 100) return b[Math.floor(num / 10)] + ' ' + numberToWordsLessThan1000(num % 10);
            else return a[Math.floor(num / 100)] + ' Hundred ' + numberToWordsLessThan1000(num % 100);
        }
    }


    const contextValue = {
        numberToWords,
        feeStructureDrop,
        handlePasteShared,
        renderFileDownloadButton,
        financialYearDropDown,
        configVar,
        onSiteSetting,
        settingOptions,
        setSettingOptions,
        renderFilePreview,
        nbCategory,
        setNbCategory,
        formTemp,
        setFormTemp,
        cityDropDown,
        setCityDropDown,
        stateDropDown,
        setStateDropDown,
        isHovered,
        setIsHovered,
        isSidebarOpen,
        setIsSidebarOpen,
        handleLogout,
        isSearch,
        globalSearch,
        user,
        userPermission,
        isLoading,
        handleAddModals,
        handleCloseModal,
        setAddModal,
        addModal,
        setIsLoading,
        setUser,
        setUserPermission,
        setGlobalSearch,
        setIsSearch
    };

    return <CommonContext.Provider value={contextValue}>{children}</CommonContext.Provider>;
}
