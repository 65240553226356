import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utiles/api';
import { CommonContext } from './CommonContext';

export const InvoiceContext = createContext();

export function InvoiceContextProvider({ children }) {
    const { handleLogout } = useContext(CommonContext);

    const [addModal, setAddModal] = useState(false);
    const [listData, setListData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [listCount, setListCount] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [viewMOpen, setViewMOpen] = useState(false);
    const [viewData, setViewData] = useState({});
    const [formGenrate, setGenrate] = useState({
        // invoice_type: 'auto',
        invoice_number: '',
        invoice_date: new Date(),
    });
    const [filters, setFilters] = useState({
        searchBy: 'member name',
        searchValue: '',
        fees_category: '',
        payment_status: '',
        start_date: null,
        end_date: null,
    });
    const [formTemp, setFormTemp] = useState({
        discount: "",
        discount_type: "Percentage",
        fees_category: "",
        fees_structure: "",
        financial_year: "",
        gst: "",
        invoice_title: "",
        member_id: "",
        member_name: "",
        net_amount: "",
        payment_status: "",
        status: 1,
        txn_amount: "",
        txn_date: "",
        txn_id: "",
    });
    const [generateModal, setGenerateModal] = useState(false);

    const handleAddModals = () => {
        setAddModal(true);
        setIsEdit(false);
    };

    const handleCloseModal = () => {
        setAddModal(false);
        setIsEdit(false);
        setFormTemp({
            discount: "",
            discount_type: "Percentage",
            fees_category: "",
            fees_structure: "",
            financial_year: "",
            gst: "",
            invoice_title: "",
            member_id: "",
            member_name: "",
            net_amount: "",
            payment_status: "",
            status: 1,
            txn_amount: "",
            txn_date: "",
            txn_id: "",
        });
    };
    const openViewModal = (id) => {
        setViewMOpen(true);
    }
    const closeViewModal = () => {
        setViewMOpen(false);
    }

    const onExport = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/payments/export', filters);
            if (response.data.s === 1) {
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onLoadApi = async (currentPage, itemsPerPage) => {
        let payloadFilters = { ...filters };
        if (activeTab > 0) {
            payloadFilters.invoice = activeTab;
        }

        setListData([]);
        setListCount('');
        setIsLoading(true);
        try {
            const response = await commonApi.post(`members/payments/list?page=${currentPage}&per_page=${itemsPerPage}`, payloadFilters);
            if (response.data.s === 1) {
                setListData(response.data.data);
                setListCount(response.data.TotalRows);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onViewApi = async (id) => {
        let payloadFilters = { ...filters };
        if (activeTab > 0) {
            payloadFilters.invoice = activeTab;
        }

        setViewData({});
        setIsLoading(true);
        try {
            const response = await commonApi.post(`members/payments/view`, { id: id });
            if (response.data.s === 1) {
                setViewData(response.data);
                // setListCount(response.data.TotalRows);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };


    // useEffect(() => {
    //     onLoadApi();
    // }, [activeTab]);

    const handleOpenGenerateModal = () => setGenerateModal(true);
    const handleCloseGenerateModal = () => {
        setGenerateModal(false);
        setGenrate({
            // invoice_type: 'auto',
            invoice_number: '',
            invoice_date: new Date(),
        })
    }

    const contextValue = {
        onExport,
        formGenrate,
        setGenrate,
        handleCloseGenerateModal,
        handleOpenGenerateModal,
        generateModal,
        setGenerateModal,
        onViewApi,
        viewData,
        setViewData,
        openViewModal,
        closeViewModal,
        viewMOpen,
        setViewMOpen,
        filters,
        setFilters,
        listCount,
        itemsPerPage,
        setItemsPerPage,
        currentPage,
        setCurrentPage,
        activeTab,
        setActiveTab,
        onLoadApi,
        isEdit,
        setIsEdit,
        formTemp,
        setFormTemp,
        addModal,
        setAddModal,
        handleCloseModal,
        handleAddModals,
        listData,
        setListData,
        isSearch,
        isLoading,
        setIsLoading,
        setIsSearch,
        generateModal,
        handleOpenGenerateModal,
        handleCloseGenerateModal
    };

    return <InvoiceContext.Provider value={contextValue}>{children}</InvoiceContext.Provider>;
}
