import React, { useContext } from 'react';
import { Card, CardBody, CardTitle, ListGroup, ListGroupItem, Container, Row, Col } from 'reactstrap';
import { CommonContext } from '../../context';

const ConfigCards = () => {
    const { configVar } = useContext(CommonContext);

    return (
        <Container className="config-cards-container">
            <Row>
                {Object.entries(configVar).map(([key, values]) => (
                    <Col md="4" sm="6" xs="12" key={key} className="mb-4">
                        <Card>
                            <CardBody>
                                <CardTitle className="card-title">
                                    {key.replace(/_/g, ' ').toUpperCase()}
                                </CardTitle>
                                <ListGroup>
                                    {Object.entries(values).map(([id, value]) => (
                                        <ListGroupItem key={id}>
                                            <strong>{id}</strong>: {value}
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default ConfigCards;
